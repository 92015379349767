<template>
  <b-container>
     <b-card class="invision-card elevate-2 invision-top borderless">
    <b-card-header>
      Redefina sua senha para continuar
    </b-card-header>
    <div class="mt-4">
      <b-form @submit.prevent="changePassword">
        <InputSenha
          id="senha"
          label="Digite a nova senha"
          v-model="model.senha"
        />
        <InputSenha
          id="confirmSenh"
          label="Confirme sua senha"
          v-model="confirmSenha"
        />
        <b-card-footer class="invision-card-footer-action">
          <b-row>
            <transition name="fade">
              <b-col class="ml-4 d-flex text-danger" v-if="error">
                <x-circle-icon class="mr-2" /><div>{{error}}</div>
              </b-col>
              <b-col class="ml-4 d-flex text-success" v-else-if="success">
                <check-circle-icon class="mr-2" /><div>Sucesso! Redirecionando...</div>
              </b-col>
            </transition>
            <b-col class="text-right">
              <b-btn variant="success" type="submit" class="invision-btn lg" :disabled="isBusy">
                <div class="d-flex" v-if="!isBusy"><div>Salvar</div></div>
                <b-row align-v="center" v-else>
                  <b-spinner variant="light" label="Entrando" small class="mr-2" /><div>Entrando...</div>
                </b-row>
              </b-btn>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-form>
    </div>
  </b-card>
  </b-container>
</template>

<script>

import loginService from '@/services/login';
import RedefinicaoApi from '@/services/redefinicao';
import { wait } from '@/helpers/common';
import InputSenha from '@/components/Form/InputSenha';

export default {
  components: {
    InputSenha,
  },
  data() {
    return {
      model: {
        redefinicao: false,
        senha: '',
      },
      confirmSenha: '',
      isBusy: false,
      error: '',
      success: false,
      redefinicaoRoute: 'redefinicao',
    };
  },
  methods: {
    async changePassword() {
      this.isBusy = true;
      this.error = '';
      this.success = false;
      try {
        if (this.model.senha !== this.confirmSenha) {
          throw 'As senhas diferem, por favor tente novamente.';
        }
        await RedefinicaoApi.update(this.model, this.redefinicaoRoute);
        const newUser = loginService.getUser();
        newUser.redefinicao = this.model.redefinicao;
        loginService.setUser(newUser);
        this.success = true;
        await wait(1000);
        this.$router.replace(this.$route.params.next || '/');
        this.isBusy = false;
        this.$root.$emit('loginChange');
      } catch (e) {
        this.isBusy = false;
        this.error = e.toString() || 'Erro desconhecido, por favor tente novamente.';
      }
    },
  },
};
</script>
